import { expternalApi, wpApi } from '../../../shared/config/axiosConfig'
import store from '../../store'
import {
  ALLOWED_EXTENSIONS,
  CHANGE_LANGUAGE,
  C_INPUT_LIST,
  FETCH_FILE_REQUEST,
  FETCH_FILE_RESULT,
  FILE_LIST,
  FILE_TYPES,
  FILE_URL,
  GIM_PROJECTS,
  GLB_LOAD_STATE,
  INDUSTRY_DATA,
  INIT_PROJECT_LIST,
  INVOICE_LIST,
  LOGGED_IN,
  LOG_OUT,
  PROJECT_LIST,
  SET_CUSTOMIZE_DATA,
  SET_DOWNLOAD_PROGRESS,
  SET_GIM_CONNECTION,
  SET_HIDE_INSTALL_BUTTON,
  SET_IFRAME_STATE,
  SET_MOBILE_VIEW,
  SET_UPLOAD_PROGRESS,
  SET_ZOOM_IN_LIMIT,
  USER_INFO,
} from '../../types/user'
import * as qs from 'qs'
const storeInstance = store()

export const setZoomInLimit = (data) => async (dispatch) => {
  dispatch({
    type: SET_ZOOM_IN_LIMIT,
    payload: data,
  })
}

export const setGlbLoadState = (data) => async (dispatch) => {
  dispatch({
    type: GLB_LOAD_STATE,
    payload: data,
  })
}

export const setCustomizeData = (data) => async (dispatch) => {
  dispatch({
    type: SET_CUSTOMIZE_DATA,
    payload: data,
  })
}

export const setIframeState = (data) => async (dispatch) => {
  dispatch({
    type: SET_IFRAME_STATE,
    payload: data,
  })
}

export const setHideInstallButton = (data) => async (dispatch) => {
  dispatch({
    type: SET_HIDE_INSTALL_BUTTON,
    payload: data,
  })
}

export const setDownloadProgress = (data) => async (dispatch) => {
  dispatch({
    type: SET_DOWNLOAD_PROGRESS,
    payload: data,
  })
}

export const setUploadProgress = (data) => async (dispatch) => {
  dispatch({
    type: SET_UPLOAD_PROGRESS,
    payload: data,
  })
}

export const setMobileView = (data) => async (dispatch) => {
  dispatch({
    type: SET_MOBILE_VIEW,
    payload: data,
  })
}

export const setGimConnection = (data) => async (dispatch) => {
  dispatch({
    type: SET_GIM_CONNECTION,
    payload: data,
  })
}

export const changeLanuage = (data) => async (dispatch) => {
  expternalApi.defaults.headers.common.Language = `${data}`
  dispatch({
    type: CHANGE_LANGUAGE,
    payload: data,
  })
}

export const getLatestAppVersion = () => async (dispatch) => {
  const response = await expternalApi({
    url: '/user/GetLatestAppVersion',
    method: 'GET',
  })
  return response.data
}

export const updateStoreProjects = (projects) => (dispatch) => {
  dispatch({
    type: PROJECT_LIST,
    payload: projects,
  })
}

export const updateStoreGimProjects = (projects) => (dispatch) => {
  dispatch({
    type: GIM_PROJECTS,
    payload: projects,
  })
}

export const updateStoreFileList = (files) => (dispatch) => {
  dispatch({
    type: FILE_LIST,
    payload: files,
  })
}

export const updateStoreFileURL = (url) => (dispatch) => {
  dispatch({
    type: FILE_URL,
    payload: url,
  })
}

export const updateStoreFileTypes = (fileTypes) => (dispatch) => {
  dispatch({
    type: FILE_TYPES,
    payload: fileTypes,
  })
}

export const updateAllowedExtensionList =
  (allowedExtensionLists) => (dispatch) => {
    dispatch({
      type: ALLOWED_EXTENSIONS,
      payload: allowedExtensionLists,
    })
  }

export const getProjects = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/SearchProjects',
    data,
  })

  if (response.data?.Result.projects) {
    const updatedProjects = response.data?.Result.projects.map((project) => {
      const tempProjects = storeInstance.getState().user.gimProjects
      // if (tempProjects?.projectList && tempProjects?.projectList?.length > 0) {
      const gimProject = storeInstance
        .getState()
        .user.gimProjects?.projectList?.filter(
          (gp) => gp.projectId === project.projectId
        )

      if (gimProject && gimProject?.length) {
        project.filePath = gimProject[0].filePath

        project.uploadGimUnit = gimProject[0]?.uploadGimUnit ?? ''
        project.downloadGimUnit = gimProject[0]?.downloadGimUnit ?? ''
        project.uploadAutoSync = gimProject[0]?.uploadAutoSync
        project.downloadAutoSync = gimProject[0]?.downloadAutoSync

        if (project?.subProjects?.length > 0) {
          project.subProjects.map((proj) => {
            const gimSubProject = storeInstance
              .getState()
              .user.gimProjects?.projectList.filter(
                (gp) => gp.projectId === proj.projectId
              )
            if (gimSubProject && gimSubProject.length) {
              proj.filePath = gimSubProject[0].filePath

              proj.uploadGimUnit = gimSubProject[0]?.uploadGimUnit ?? ''
              proj.downloadGimUnit = gimSubProject[0]?.downloadGimUnit ?? ''
              proj.uploadAutoSync = gimSubProject[0]?.uploadAutoSync
              proj.downloadAutoSync = gimSubProject[0]?.downloadAutoSync
            }
          })
        }
      } else {
        project.filePath = ''
      }

      if (project?.customBuilt && project?.subProjects?.length > 0) {
        project.subProjects.map((proj) => {
          if (proj?.customBuilt && proj?.subProjectId === 0) {
            proj.parentProjectId = proj?.projectId
            proj.projectId = `${proj?.projectId}_${proj?.subProjectId}`
          }
        })
      }
      // }

      if (data?.subProjectId === 0 && project?.customBuilt) {
        project.subProjectId = 0
        project.parentProjectId = project?.projectId
        project.projectId = `${project?.projectId}_${data?.subProjectId}`
      }

      project.uuid = ''
      project.name = project.projectName
      project.type = 'project'
      return project
    })
    dispatch(updateStoreProjects(updatedProjects))
    return updatedProjects
  }
  return response.data?.Result
}

export const getPlots = (data) => async (dispatch) => {
  try {
    const response = await expternalApi({
      method: 'GET',
      url: `Project/SearchPlotIds?${data}`,
    })
    return response?.data
  } catch (e) {
    // if (response.data.projects) {
  }
}

export const getObjectByPlotId = (data) => async (dispatch) => {
  try {
    const response = await expternalApi({
      method: 'GET',
      url: `Project/GetObjectIdsByPlotId?${data}`,
    })
    return response?.data
  } catch (e) {
    // if (response.data.projects) {
  }
}

export const getTransferObject = (data) => async (dispatch) => {
  try {
    const response = await expternalApi({
      method: 'GET',
      url: `object/GetGIMTransferObject?${data}`,
    })
    return response?.data
  } catch (e) {
    // if (response.data.projects) {
  }
}

export const getObjectGLBModelsBatch = (data) => expternalApi.get("object/GetBatchGLBObjectModels", {
  params: data,
  paramsSerializer: function (params) {
    return qs.stringify(params, {arrayFormat: 'repeat'})
  }
});




// async (data) =>{
//   const response = await expternalApi({
//     method: "GET",
//     url: `object/GetBatchGLBObjectModels`,
//     params: data,
//   })

//   return response?.data
// }

export const getObjects = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'object/GetObjectSearch',
    data,
  })

  return response.data?.Result
}

export const addObject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'object/AddObjectData',
    data,
  })
  return response.data
}

export const postObjectData = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'object/PostObjectData',
    data,
  })
  return response.data
}

export const deleteObject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'object/DeleteObject',
    data,
  })
  return response.data
}

export const shareObjects = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'object/SendGIMTransferLink',
    data,
  })

  return response.data?.Result
}

export const checkFileOnServer = (guid, type) => async (dispatch) => {
  let fileType = type ? type?.toLowerCase() : 'json'
  // dispatch({ type: FETCH_FILE_REQUEST })
  const response = await expternalApi({
    method: 'GET',
    url: `TestUploadFiles/${guid}.${fileType}`,
  })
    .then((res) => {
      return res
    })
    .catch((e) => {
      return e?.response ?? e
    })
  // const response = await axios.get(
  //   // `object/GetDownloadFile?filename=${guid}.gltf`
  //   // `object/GetDownloadFileStream?filename=${guid}.gltf`
  //   // `https://gimtransfer.com/3D_Models/${guid}.gltf`
  //   // `${process.env.REACT_APP_BE_DOMAIN}GIMtemp/${guid}.gltf`
  //   `${process.env.REACT_APP_BE_DOMAIN}TestUploadFiles/${guid}.gltf`
  //   // {
  //   //   headers: {
  //   //     'Access-Control-Allow-Origin': '*',
  //   //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //   //   },
  //   // }
  // )
  // dispatch({ type: FETCH_FILE_RESULT })
  return response
}

export const fetchFileFromServer =
  (guid, type, isGIMTransfer) => async (dispatch) => {
    let fileType = type ? type?.toLowerCase() : 'json'
    let flag = isGIMTransfer ? '&isGIMtransfer=true' : ''
    dispatch({ type: FETCH_FILE_REQUEST })
    const response = await expternalApi({
      method: 'GET',
      url: `/object/GetFileResponse?filename=${guid}.${fileType}${flag}`,
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        return e?.response ?? e
      })
    // const response = await axios.get(
    //   // `object/GetDownloadFile?filename=${guid}.gltf`
    //   // `object/GetDownloadFileStream?filename=${guid}.gltf`
    //   // `https://gimtransfer.com/3D_Models/${guid}.gltf`
    //   // `${process.env.REACT_APP_BE_DOMAIN}GIMtemp/${guid}.gltf`
    //   `${process.env.REACT_APP_BE_DOMAIN}TestUploadFiles/${guid}.gltf`
    //   // {
    //   //   headers: {
    //   //     'Access-Control-Allow-Origin': '*',
    //   //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //   //   },
    //   // }
    // )
    dispatch({ type: FETCH_FILE_RESULT })
    return response
  }

export const signUpwithLinkedIn = (data) => async (dispatch) => {
  try {
    const response = await expternalApi({
      method: 'POST',
      url: '/user/SignUpWithLinkedIn',
      data,
    })
    // if (response.status === 200 && !response.data.hasError) {

    if (response?.data?.Result?.token) {
      expternalApi.defaults.headers.common.Authorization = `Bearer ${response?.data?.Result?.token}`
      dispatch({
        type: LOGGED_IN,
        payload: response?.data?.Result,
      })
      // }
    }
    return response?.data?.Result
  } catch (e) {
    console.log('loggedin>>>err', e, e?.response)
  }
}

export const userSignOut = (data) => async (dispatch) => {
  expternalApi.defaults.headers.common.Authorization = null
  dispatch({
    type: LOG_OUT,
  })
}

export const getAllProjects = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `Project/GetAllProjects${data}`,
  })
  return response.data
}

export const getProjectById = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/GetProjectById',
    data,
  })
  return response.data
}

export const updateCustomStyle = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/UpdateCustomStyle',
    data,
  })
  return response.data
}

export const getCustomStyleByProjectId = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `Project/GetCustomStyleByProjectId?${data}`,
  })
  if (!!response?.data?.Result && response?.data?.Result?.trim()?.length > 0) {
    dispatch({
      type: SET_CUSTOMIZE_DATA,
      payload: JSON.parse(response?.data?.Result),
    })
  } else {
    dispatch({
      type: SET_CUSTOMIZE_DATA,
      payload: null,
    })
  }
  return response.data
}

export const updateProjectById = (data) => async (dispatch) => {
  const response = await expternalApi.post('Project/UpdateProject', data)
  // const response = await expternalApi({
  //   method: 'POST',
  //   url: `/Project/UpdateProject?projectid=${data.projectid}&projectname=${data.projectName}&projectdescription=${data.Description}`,
  //   // id,
  // })
  return response.data
}

export const getAllUsers = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/user/GetAllUsers',
  })
  return response.data
}

export const createProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/InsertProject',
    data,
  })
  return response.data
}

export const deleteProjectById = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/DeleteProject',
    data,
  })
  return response.data
}

export const addMemberToProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/AddProjectMember',
    data,
  })
  return response.data
}

export const updateMemberToProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/UpdateProjectMember',
    data,
  })
  return response.data
}

export const removeMemberFromProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: 'Project/DeleteRevertProjectMember',
    data,
  })
  return response.data
}

export const getAllProjectMembers = (params) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',  
    url: 'Project/GetProjectMembers',
    params,
  })

  return response.data?.Result
}

export const CheckDomainByProjectId = (data) => async (dispatch) => {
  try {
    const response = await expternalApi({
      method: 'POST',
      url: 'Project/CheckDomainByProjectId',
      data,
    })

    if (response.data.ProjectDetails) {
      let projects = [response?.data?.ProjectDetails]
      const updatedProjects = projects.map((project) => {
        const tempProjects = storeInstance.getState().user.gimProjects
        if (tempProjects && tempProjects?.length > 0) {
          const gimProject = storeInstance
            .getState()
            .user.gimProjects.filter((gp) => gp.projectId === project.ProjectId)
          if (gimProject && gimProject.length) {
            project.filePath = gimProject[0].filePath
          } else {
            project.filePath = ''
          }
        }
        project.projectMemberAccessRights =
          project?.projectMembers?.length > 0
            ? project?.projectMembers?.[0]?.AccessRights ?? ''
            : ''
        project.uuid = ''
        project.name = project.projectName
        project.type = 'project'
        project.isProjectMember = true
        return project
      })
      dispatch(updateStoreProjects(updatedProjects))
    }
    return response.data
  } catch (e) {
    console.log(e, e?.response)
  }
}

export const getIndustryList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/Project/GetIndustryList',
  })
  return response
}

export const getBuildingList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/Project/GetBuildingTypes',
  })
  return response
}

export const checkEmailVerifyStatus = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/CheckVericationInvoiceAddress',
    data,
  })
  return response
}

export const verifyEmail = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/VerifyInvoiceAddress',
    data,
  })
  return response
}

export const getInvoiceList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/Project/GetInvoicePaymentList${data}`,
  })
  return response
}

export const updatePaymentStatus = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/UpdatePaymentStatus',
    data,
  })
  return response
}

export const lockUnlockProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/LockUnlockProject',
    data,
  })
  return response
}

export const activeInactiveProject = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/ActiveInactiveProject',
    data,
  })
  return response
}

export const sendEmailInvoice = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/SendInvoicePerMonth',
    data,
  })
  return response
}

export const getProjectOverviewList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/Project/GetProjectOverviewList${data}`,
  })
  return response
}

export const updateProjectOverview = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/UpdateProjectOverview',
    data,
  })
  return response
}

export const deleteRetrieveProjectOverview = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/DeleteRevertProjectOverview',
    data,
  })
  return response
}

export const getFileTypeList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/Project/GetFileTypeList',
  })
  return response
}

export const updateUserSettings = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: `/object/UpdateUserSettings/${data?.update}`,
    data: data?.payload,
  })
  return response
}

export const getUserSettings = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/object/GetUserSettings?userId=${data}`,
    data,
  })
  return response
}

export const getFileTypesList = (params) => async (dispatch) => {
  // const response = await expternalApi({
  //   method: 'POST',
  //   url: '/Project/GetFileTypesByProjectId',
  //   data,
  // })
  const response = await expternalApi({
    method: 'GET',
    url: '/object/GetFileTypesByGuid',
    params,
  })
  return response.data
}

export const checkFileForDirectDownload = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/object/CheckExistFile?filename=${data}`,
    data,
  })
  return response
}

export const getTestResult = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/object/GetTestResult${data}`,
  })
  return response
}

export const updateTestResult = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/object/AddUpdateTestResult',
    data,
  })
  return response
}

// Test result
export const getTestFileList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/object/GetTestFileList',
  })
  return response
}
export const getTestVersionList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: '/object/GetVersionList',
  })
  return response
}
export const getAllInstances = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/Project/GetInstanceListByRegion?${data}`,
    // url: `/Project/GetAllInstances?${data}`,
  })
  return response
}

export const getObjectDefinition = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/GetDefinitionData',
    data,
  })
  return response
}

export const getDefinitionInstances = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/GetDefinitionInstances',
    data,
  })
  return response
}

export const getLocationList = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/Project/GetObjectLocationList?${data}`,
  })
  return response
}

export const getLocationById = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'GET',
    url: `/Project/GetObjectLocationById?${data}`,
  })
  return response
}

export const addObjectLocation = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/AddObjectLocation',
    data,
  })
  return response
}

export const updateObjectLocation = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/UpdateObjectLocation',
    data,
  })
  return response
}

export const deleteObjectLocation = (data) => async (dispatch) => {
  const response = await expternalApi({
    method: 'POST',
    url: '/Project/DeleteObjectLocation',
    data,
  })
  return response.data
}

// Test cases
export const setInitialtProjectList = (projects) => (dispatch) => {
  dispatch({
    type: INIT_PROJECT_LIST,
    payload: projects,
  })
}

export const setSelectedProjectData = (projects) => (dispatch) => {
  dispatch({
    type: C_INPUT_LIST,
    payload: projects?.[0],
  })
}

export const industryData = (industry) => (dispatch) => {
  dispatch({
    type: INDUSTRY_DATA,
    payload: industry,
  })
}

export const userInfoDetails = (userInfo) => (dispatch) => {
  dispatch({
    type: USER_INFO,
    payload: userInfo,
  })
}
export const invoiceListData = (invoice) => (dispatch) => {
  dispatch({
    type: INVOICE_LIST,
    payload: invoice,
  })
}

export const getWpPage = (data) => async (dispatch) => {
  const response = await wpApi({
    method: 'GET',
    url: `${data}`,
  })
  return response
}
